<template>
  <div class="top-line">
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-1">Contact Information</h6>
      <div class="d-flex justify-content-end action-wrapper" v-if="isCandidate">
        <a class="px-2 btn" v-if="isEditable" @click="editContactInfo">
          <i class="fas fa-pencil-alt" alt="Edit" />
        </a>
      </div>
    </div>
    <div
      v-if="
        isUKMainAgency &&
        profile &&
        profile.agency_contact &&
        profile.agency_contact.contact
      "
      class="mb-1 cursor-pointer"
    >
      <div
        class="d-flex align-items-center justify-content-between"
        v-if="profile.organisation_name"
      >
        <CBadge
          color="danger"
          class="p-1"
          @click="isShowAgencyContact = !isShowAgencyContact"
          v-c-tooltip="' Click to Show / Hide Agency Contact Information'"
          >{{ `Agency - ${candidateOrganisationName}` }}</CBadge
        >
      </div>
      <div v-if="isShowAgencyContact" class="border-bottom-primary">
        <CRow class="pb-2 m-0">
          <span>
            <span class="text-gray fs-12">{{ agencyContactName }}</span>
          </span>
        </CRow>
        <CRow class="pb-2 m-0">
          <div class="d-flex justify-content-between">
            <span>
              <i class="far fa-envelope mr-2"></i>
            </span>
            <div class="d-flex justify-content-start sticky-header">
              <span>
                <span
                  class="d-block mx-76"
                  :style="'color: red;'"
                  @click="openEmailModal(agencyContactEmail)"
                  >{{ agencyContactEmail }}</span
                >
              </span>
            </div>
          </div>
        </CRow>
        <CRow class="pb-2 m-0">
          <div class="d-flex justify-content-between">
            <span>
              <i class="fa fa-phone mr-2"></i>
            </span>
            <div class="d-flex justify-content-start sticky-header">
              <span>
                <span class="d-block" :style="'color: red;'">{{
                  agencyContactPhone
                }}</span>
              </span>
            </div>
          </div>
        </CRow>
      </div>
    </div>
    <div v-if="isCandidateSupplierFromAccessToken && mainContactInfo" class="mb-1 cursor-pointer">
      <CBadge
        color="danger"
        class="p-1"
        @click="isShowMainAgencyContact = !isShowMainAgencyContact"
        v-c-tooltip="' Click to Show / Main Agency Contact Information'"
        >{{ `Main Agency - ${mainContactInfo.parent_organisation_name}` }}</CBadge
      >
      <div v-if="isShowMainAgencyContact" class="border-bottom-primary mt-1">
        <CRow class="pb-2 m-0" v-if="mainContactInfo.email">
          <div class="d-flex justify-content-between">
            <span>
              <i class="far fa-envelope mr-2"></i>
            </span>
            <div class="d-flex justify-content-start sticky-header">
              <span>
                <span
                  class="d-block mx-76"
                  >{{ mainContactInfo.email }}</span
                >
              </span>
            </div>
          </div>
        </CRow>
                <CRow class="pb-2 m-0" v-if="mainContactInfo.phone_no">
          <div class="d-flex justify-content-between">
            <span>
              <i class="fa fa-phone mr-2"></i>
            </span>
            <div class="d-flex justify-content-start sticky-header">
              <span>
                <span
                  class="d-block mx-76"
                  >{{ mainContactInfo.phone_no | formatPhone }}</span
                >
              </span>
            </div>
          </div>
        </CRow>
      </div>
    </div>
    <div>
      <CRow class="mb-2" v-if="preferredContactMethod">
        <CCol col="12" class="text-gray">Preferred Contact Method</CCol>
        <CCol col="12" class="text-black">
          {{ preferredContactMethod | preferred_contact_method }}
        </CCol>
      </CRow>
      <CRow class="pb-2 m-0">
        <div class="d-flex justify-content-between">
          <span>
            <i class="far fa-envelope mr-2"></i>
          </span>
          <div class="d-flex justify-content-start sticky-header">
            <span>
              <span
                class="d-block cursor-pointer"
                :style="
                  preferredContactMethod.includes('email')
                    ? 'color: red;'
                    : 'color: black;'
                "
                @click="openEmailModal(email)"
                >{{ email }}</span
              >
              <span
                v-if="
                  alternate_email &&
                  !checkSameEmail
                "
                class="d-block cursor-pointer"
                :style="
                  preferredContactMethod.includes('email')
                    ? 'color: blue;'
                    : 'color: black;'
                "
                @click="openEmailModal(alternate_email)"
              >
                {{ alternate_email }}
              </span>
              <span 
                v-else-if="!checkSameEmail" 
                class="d-block cursor-pointer"
                :style="
                  preferredContactMethod.includes('email')
                    ? 'color: blue;'
                    : 'color: black;'
                "
              >
                {{ alternate_email }}
              </span>
            </span>
          </div>
        </div>
      </CRow>
      <CRow class="pb-2 m-0">
        <div class="d-flex justify-content-between">
          <span>
            <i class="fa fa-phone mr-2"></i>
          </span>
          <div class="d-flex justify-content-start sticky-header">
            <span>
              <span
                class="d-block"
                :style="
                  preferredContactMethod.includes('phone')
                    ? 'color: red;'
                    : 'color: black;'
                "
                >{{ phone }}</span
              >
              <span class="text-black d-block" v-if="alternate_phone">
                {{ alternate_phone }}
              </span>
            </span>
          </div>
        </div>
      </CRow>
      <CRow class="pb-2 m-0">
        <span>
          <i class="fab fa-whatsapp mr-2"></i>
        </span>
        <div class="d-flex justify-content-start sticky-header">
          <span
            class="d-block"
            :style="
              preferredContactMethod.includes('whatsapp')
                ? 'color: red;'
                : 'color: black;'
            "
            >{{ whatsapp }}</span
          >
        </div>
      </CRow>
      <div v-if="isCandidate">
        <CRow class="pb-2 m-0">
          <span>
            <i class="fab fa-linkedin mr-2"></i>
            <span class="text-black">{{ linkedin_address }}</span>
          </span>
        </CRow>
        <CRow class="pb-2 m-0">
          <span>
            <i class="fab fa-skype text-gray mr-2"></i>
            <span class="text-black">{{ skype_id }}</span>
          </span>
        </CRow>
        <CRow class="pb-2 m-0">
          <div class="d-flex justify-content-between">
            <span>
              <i class="fa fa-globe mr-2"></i>
              <a
                v-if="profile.personal_website"
                target="_blank"
                :href="`http://www.${profile.personal_website}`"
                >{{ profile.personal_website }}</a
              >
              <span v-else class="text-black">--</span>
            </span>
          </div>
        </CRow>
        <CRow class="pb-2 m-0" v-if="isCandidate">
          <div class="d-flex justify-content-between">
            <span>
              <i
                class="fas fa-map-marker-alt text-gray mr-2"
                style="color: #ea4335"
              ></i>
            </span>
            <div class="d-flex justify-content-start sticky-header">
              <span>{{ currentLocation || "--" }}</span>
            </div>
          </div>
        </CRow>
      </div>
    </div>
    <CRow class="mt-2 justify-content-center" v-if="!isCandidateRole">
      <CButton color="primary" @click="openCommentModal()"
        >Private Comments ({{ getComments.length }})</CButton
      >
    </CRow>
    <ContactInfoEdit
      :candidateInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="contactInfoModalCallBack"
      v-if="EditModal.isShowPopup"
    />
    <CandidateCommentModal
      v-if="commentModal.isShowPopup"
      :isShowPopup="commentModal.isShowPopup"
      :candidateID="commentModal.candidateID"
      @commentModalCallBack="commentModalCallBack"
    />
    <EmailModal
      v-if="emailModal.email"
      :isShowPopup="emailModal.email"
      :email_id="emailId"
      :candidate_uid="profile.candidate_uid"
      :alternate_email="profile.alternate_email"
      @emailModalCallBack="modalCallBack"
      @conversationModalCallBack="conversationModalCallBack"
    />
    <DeleteModal
      v-if="deleteModal.isShowPopup"
      :isShowPopup="deleteModal.isShowPopup"
      :delete_id="deleteModal.delete_id"
      :email_subject="deleteModal.subject"
      @deleteModalCallBack="deleteModalCallBack"
      @emailModalCallBack="modalCallBack"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Role, getScope } from "@/helpers/helper";
import ContactInfoEdit from "./EditForm/ContactInfoEdit";
import CandidateCommentModal from "@/containers/CandidateDetailsTalentFind/ProfileSection/CandidateCommentModal";
import EmailModal from "@/containers/Communication/EmailModal";
import DeleteModal from "@/containers/Communication/DeleteModal";
import isJobSupplier from "@/mixins/isJobSupplier";
import _ from "lodash";
export default {
  mixins: [isJobSupplier],
  props: {
    isHospital: {
      type: Boolean,
      default: false,
    },
    isSideBar: {
      type: Boolean,
      default: false,
    },
    isCandidate: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: Object,
    },
    isEditable:{
      type: Boolean,
      default :false,
    }
  },
  components: {
    ContactInfoEdit,
    CandidateCommentModal,
    EmailModal,
    DeleteModal,
  },
  data() {
    return {
      Role,
      currentUserRole: getScope(),
      EditModal: {
        isShowPopup: false,
      },
      commentModal: {
        isShowPopup: false,
        candidateID: null,
      },
      emailId: null,
      emailModal: {
        email: false,
      },
      deleteModal: {
        isShowPopup: false,
        delete_id: null,
        subject: null,
      },
      isShowAgencyContact: false,
      isShowMainAgencyContact: false,
    };
  },
  computed: {
    ...mapGetters([
      "getSelectedCandidateDataForJobsList",
      "getJobDetailsById",
      "locationCountryInfo",
      "getCandidateCommonComments",
      "isUKMainAgency",
      "isCandidateSupplierFromAccessToken",
      "currentUserType",
      "mainContactInfo",
    ]),
    checkSameEmail() {
      return (
        (this.email &&
          this.alternate_email &&
          _.isEqual(
            this.email.toLowerCase(),
            this.alternate_email.toLowerCase()
          )) ||
        false
      );
    },
    email() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact?.email || "--"
        );
      } else if (this.isSideBar) {
        return this.getSelectedCandidateDataForJobsList?.email || "--";
      } else {
        return this.profile?.email || "--";
      }
    },
    alternate_email() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact?.alt_email || null
        );
      } else if (this.isSideBar) {
        return (
          this.getSelectedCandidateDataForJobsList?.alternate_email || null
        );
      } else {
        return this.profile?.alternate_email || null;
      }
    },
    phone() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact?.phone_no?.replace(
            "^",
            "-"
          ) || "--"
        );
      } else if (this.isSideBar) {
        return (
          this.getSelectedCandidateDataForJobsList?.phone?.replace("^", "-") ||
          "--"
        );
      } else {
        return this.profile?.phoneNumber || "--";
      }
    },
    alternate_phone() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact?.alt_phone_no?.replace(
            "^",
            "-"
          ) || null
        );
      } else if (this.isSideBar) {
        return (
          this.getSelectedCandidateDataForJobsList?.alternate_phone?.replace(
            "^",
            "-"
          ) || null
        );
      } else {
        return this.profile?.alternatePhoneNumber || null;
      }
    },
    whatsapp() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact?.prefer_whats_app?.replace(
            "^",
            "-"
          ) || "--"
        );
      } else if (this.isSideBar) {
        return (
          this.getSelectedCandidateDataForJobsList?.whatsapp?.replace(
            "^",
            "-"
          ) || "--"
        );
      } else {
        return this.profile?.whatsappNumber || "--";
      }
    },
    linkedin_address() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact
            ?.linkedin_address || "--"
        );
      } else if (this.isSideBar) {
        return (
          this.getSelectedCandidateDataForJobsList?.linkedin_address || "--"
        );
      } else {
        return this.profile?.linkedin_address || "--";
      }
    },
    skype_id() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact?.skype || "--"
        );
      } else if (this.isSideBar) {
        return this.getSelectedCandidateDataForJobsList?.skype_id || "--";
      } else {
        return this.profile?.skype_id || "--";
      }
    },
    address_line_1() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact?.address || null
        );
      } else if (this.isSideBar) {
        return this.getSelectedCandidateDataForJobsList?.address_line_1 || null;
      } else {
        return this.profile?.address_line_1 || null;
      }
    },
    address_line_2() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact?.address2 || null
        );
      } else if (this.isSideBar) {
        return this.getSelectedCandidateDataForJobsList?.address_line_2 || null;
      } else {
        return this.profile?.address_line_2 || null;
      }
    },
    currentLocation() {
      return (
        this.locationCountryInfo &&
        this.locationCountryInfo.length &&
        this.profile.country_id &&
        this.locationCountryInfo.filter(
          (val) => val.code === this.profile.country_id
        )[0].label
      );
    },
    preferredContactMethod() {
      return this.profile.preferred_contact_method || "";
    },
    getComments() {
      return this.getCandidateCommonComments;
    },
    isCandidateRole() {
      return (
        this.currentUserRole === Role.customerCandidate ||
        this.currentUserRole === Role.systemCandidate
      );
    },
    candidateOrganisationName() {
      return this.profile?.organisation_name || "--";
    },
    agencyContactName() {
      return this.profile?.agency_main_contact?.name || "--";
    },
    agencyContactEmail() {
      return this.profile?.agency_main_contact?.email || "--";
    },
    agencyContactPhone() {
      return (
        this.profile?.agency_main_contact?.phone_no.replace("^", "-") || "--"
      );
    },
  },
  methods: {
    ...mapActions([
      "updateCandidate",
      "initFetchContactInfoOptions",
      "fetchCandidateCommonComments",
    ]),
    editContactInfo() {
      this.EditModal.isShowPopup = true;
    },
    contactInfoModalCallBack(action, data) {
      if (action === false) this.EditModal.isShowPopup = false;
      if (action === true && data != null) {
        const { customer_uid } = this.profile;
        let appentAction = this.updateCandidate({ data, customer_uid });
        Promise.all([appentAction]).then((res) => {
          if (res) this.EditModal.isShowPopup = false;
        });
      }
    },
    openCommentModal() {
      this.commentModal.candidateID = this.profile?.candidate_uid;
      this.commentModal.isShowPopup = true;
    },
    commentModalCallBack() {
      this.commentModal.isShowPopup = false;
    },
    openEmailModal(emailId) {
      if (
        this.currentUserRole === this.Role.systemCandidate ||
        this.currentUserRole === this.Role.customerCandidate
      ) {
        return;
      }
      this.emailId = emailId;
      this.emailModal.email = true;
    },
    modalCallBack(columnKey) {
      this.emailModal[columnKey] = false;
    },
    conversationModalCallBack(delete_id, subject) {
      this.deleteModal.isShowPopup = true;
      this.deleteModal.delete_id = delete_id;
      this.deleteModal.subject = subject;
    },
    deleteModalCallBack() {
      this.deleteModal.isShowPopup = false;
      this.emailModal.email = true;
    },
  },
  mounted() {
    this.initFetchContactInfoOptions();
    this.fetchCandidateCommonComments(this.profile?.candidate_uid);
  },
  filters: {
    preferred_contact_method(data) {
      const preferred_contact_method = {
        phone: "Phone",
        whatsapp: "WhatsApp",
        email: "Email",
        sms: "SMS",
      };
      let contact_methods = data
        .split(",")
        .map((e) => preferred_contact_method[e.trim()])
        .join(", ");
      return contact_methods;
    },
    formatPhone(data){
      if(data)
        return data.replace("^", "-");
      return '--'
    }
  },
};
</script>